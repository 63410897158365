import logo from './logo.svg';
import Form from "./Form"
function App() {
  return (
    <div className="">
        <Form />
    </div>
  );
}

export default App;
